<script>
  import { globalData, inform, rest, shownModal, t } from '@dabble/app';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import dabbleUInverse from '../images/dabble-u-inverse.svg';
  import dabbleURgular from '../images/dabble-u.svg';
  import nanowrimoInverse from '../images/nanowrimo-inverse.svg';
  import nanowrimoRegular from '../images/nanowrimo.svg';
  import { challengeStore, nanowrimo, nanowrimoAuthExpired } from '../nanowrimo';

  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCancel, mdiCheckCircleOutline, mdiLoading, mdiOpenInNew } from '@mdi/js';

  import { plugins } from '@dabble/app';
  import { isCamp, monthAfter } from '../nanowrimo';

  const { uiTheme } = plugins.stores;

  $: nanowrimoLogo = $uiTheme === 'dark' ? nanowrimoInverse : nanowrimoRegular;
  $: dabbleU = $uiTheme === 'dark' ? dabbleUInverse : dabbleURgular;

  $: checkingCircle = false;
  $: nanoEnabled = $globalData?.nanowrimoData?.enabled;
  $: subscription = $plugins.subscription;
  $: status = getSubscriptionStatus(subscription);
  $: canHaveTrial = !$isCamp && status !== 'active';
  // An extended trial will finish Dec 2nd, so trial_end will be greater than Dec 1st, no matter the timezone
  $: hasExtendedTrial = subscription?.trial_end > $monthAfter && status === 'trialing';
  $: canStartOrExtendTrial = canHaveTrial && (status === 'canceled' || !hasExtendedTrial);

  $: isMember = $challengeStore?.isMember;
  $: hasJoinedChallenge = $challengeStore?.participating;
  $: hasAuthWithNano = $nanowrimo.auth && !$nanowrimoAuthExpired;
  $: hasJoinedNaNo = $nanowrimo?.participating;
  $: canJoin = isMember || hasAuthWithNano;
  $: alreadyJoined = hasJoinedChallenge || hasJoinedNaNo;
  $: if ($shownModal === 'nanowrimoInvite') checkCircle();

  async function joinChallenge() {
    if (canStartOrExtendTrial) plugins.stores.billing.startNaNoWriMoTrial();

    let justJoined = false;

    if (isMember && !hasJoinedChallenge) {
      challengeStore.update({ participating: true });
      hasJoinedChallenge = true;
      justJoined = true;
    }

    if (hasAuthWithNano) {
      $shownModal = 'nanowrimoSettings';
      if (!hasJoinedNaNo) {
        nanowrimo.update({ participating: true });
      }
    } else if (!nanoEnabled) {
      nanowrimo.update({ participating: true });
      hasAuthWithNano = true;
    } else {
      nanowrimo.update({ participating: false });
    }

    if (hasAuthWithNano) {
      $shownModal = 'nanowrimoSettings';
    } else {
      $shownModal = justJoined ? 'challengeJoined' : 'challengeSettings';
    }
  }

  function getSubscriptionStatus(subscription) {
    if (!subscription) return 'canceled';
    if (subscription.status === 'trialing') return 'trialing';
    return 'active';
  }

  function closeModal() {
    $shownModal = null;
  }

  async function decline() {
    closeModal();
    challengeStore.update({ participating: false });
    nanowrimo.update({ participating: false });
    inform('info', $t('nanowrimo_maybe_later'), $t('nanowrimo_turn_on_later'));
  }

  async function checkCircle() {
    if ($challengeStore.isMember) return;
    checkingCircle = true;
    try {
      const { isMember } = await rest.GET('/circle/check-60day');
      challengeStore.update({ isMember, modified: Date.now() });
    } catch (e) {}
    checkingCircle = false;
  }

  function openNanoAuth() {
    const left = screenLeft + (outerWidth - 500) / 2;
    const top = screenTop + (outerHeight - 600) / 2;
    open(
      `https://accounts.dabblewriter.com/nanowrimo/auth`,
      '_blank',
      `popup,innerWidth=500,innerHeight=600,left=${left},top=${top}`
    );
  }

  function getActionText(canStartOrExtendTrial, alreadyJoined, status) {
    if (canStartOrExtendTrial) {
      return status === 'canceled' ? 'challenge_trial_start' : 'challenge_trial_extend';
    }
    if (alreadyJoined) {
      return 'challenge_goto_settings';
    }
    return 'challenge_join';
  }
</script>

{#if $shownModal === 'nanowrimoInvite'}
  <Modal title={$t('nanowrimo_title')} on:close={closeModal} size="large">
    <p class="lead">{@html $t('nanowrimo_lead')}</p>
    <section class="nano-invite-main">
      <div>
        <img src={dabbleU} alt="Dabble U" />
        <ul>
          {@html $t('dabbleu')}
          {#if canHaveTrial}
            {@html $t('free_trial')}
          {/if}
        </ul>
      </div>
      <div>
        <img src={nanowrimoLogo} alt="NaNoWriMo" />
        <ul>
          {@html $t('nanowrimo_sync', { class: nanoEnabled ? '' : ' class=noSync' })}
          {@html $t('nanowrimo_list')}
          {#if canHaveTrial}
            {@html $t('free_trial')}
          {/if}
        </ul>
      </div>
    </section>
    <div class="nano-invite-main">
      <div class="adjust">
        {#if checkingCircle}
          <Icon path={mdiLoading} spin />
        {:else if isMember}
          <div class="joined">
            <Icon path={mdiCheckCircleOutline} /> <strong>{$t('dabbleu_joined')}</strong>
          </div>
          {#if hasJoinedChallenge}
            <div class="joined">
              <Icon path={mdiCheckCircleOutline} /> <strong>{$t('challenge_participation')}</strong>
            </div>
          {:else}
            <div class="joined not">
              <Icon path={mdiCancel} /> <strong>{$t('challenge_participation')}</strong>
            </div>
          {/if}
        {:else}
          <a
            href="https://www.storycraft.cafe/sign_up?post_login_redirect=https%3A%2F%2Fwww.storycraft.cafe%2Fc%2Fjoin-the-challenge%2F&request_host=www.storycraft.cafe&user%5Binvitation_token%5D=#email"
            target="_blank"
            class="btn primary">{$t('dabbleu_join')} <Icon path={mdiOpenInNew} inline /></a
          >
        {/if}
      </div>
      {#if nanoEnabled}
        <div class="adjust2">
          {#if hasAuthWithNano}
            <div class="joined">
              <Icon path={mdiCheckCircleOutline} /> <strong>{$t('nanowrimo_joined')}</strong>
            </div>

            {#if hasJoinedNaNo}
              <div class="joined">
                <Icon path={mdiCheckCircleOutline} /> <strong>{$t('nanowrimo_participation')}</strong>
              </div>
            {:else}
              <div class="joined not">
                <Icon path={mdiCancel} /> <strong>{$t('nanowrimo_participation')}</strong>
              </div>
            {/if}
          {:else}
            <a
              href="https://accounts.dabblewriter.com/nanowrimo/auth"
              target="_blank"
              class="btn primary"
              on:click|preventDefault={openNanoAuth}
              >{$t('nanowrimo_authenticate')} <Icon path={mdiOpenInNew} inline /></a
            >
          {/if}
        </div>
      {/if}
    </div>
    <footer>
      <button
        class="btn primary"
        class:outline={!canStartOrExtendTrial && alreadyJoined}
        on:click={joinChallenge}
        disabled={!canJoin}
      >
        {$t(getActionText(canStartOrExtendTrial, alreadyJoined, status))}
      </button>
      {#if alreadyJoined}
        <button class="btn" on:click={closeModal}>{$t('close')}</button>
      {:else}
        <button class="btn" on:click={decline}>{$t('nanowrimo_not_interested')}</button>
      {/if}
    </footer>
  </Modal>
{/if}

<style>
  .nano-invite-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    padding-bottom: 2rem;
  }

  :global(.noSync) {
    color: var(--text-color-lightest);
    text-decoration: line-through;
  }

  .nano-invite-main > div {
    width: 400px;
  }

  .nano-invite-main .adjust {
    padding-left: 2.5rem;
  }

  .nano-invite-main .adjust2 {
    padding-left: 1.5rem;
  }

  .joined {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  .joined :global(svg) {
    color: var(--brand-primary);
    font-size: 1.5rem;
  }

  .joined.not {
    color: var(--text-color-lightest);
  }
  .joined.not :global(svg) {
    color: var(--brand-danger);
    font-size: 1.5rem;
  }
  @media (min-width: 992px) {
    .nano-invite-main {
      flex-direction: row;
      align-items: flex-start;
    }
    .nano-invite-main > div {
      width: 100%;
    }
  }
</style>
